import React from "react"
import styled from "styled-components"
import { transparentize } from "polished"
import Box from "shared/components/Box"
import { ListItem } from "shared/containers/List"
import { Link } from "react-router-dom"
import { sm, md } from "mill/utils/breakpoints"

export const Wrapper = styled.div`
  max-width: 105rem;
  padding: 0 3rem;
  margin: 0 auto;
`

export const Gradient = styled.div`
  background: linear-gradient(180deg, #f4f4f4 0%, #fff 50rem, #fff 100%);
  flex: 1;
  padding-bottom: 3rem;
`

export const Profile = styled.div`
  background: white;
  border-radius: 1.2rem;

  strong {
    color: ${props => props.theme.colors.primary};
  }
`

export const ProfileHeader = styled(Box)`
  @media ${sm} {
    border-right: 1px solid #F0F0F0;
  }
`

export const Statement = styled.div`
  display: flex;
  justify-content: center;
  display: block;
  min-width: 16rem;
  font-weight: bold;
  font-size: 1.1rem;
  color: #d4d4d4;

  span {
    display: block;
    color: ${props => props.theme.colors.primary};
    font-size: 1.8rem;
  }
`

export const CampaignList = styled.div`
  @media ${sm} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.5rem;
  }

  @media ${md} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const CampaignCard = styled(Link)`
  display: grid;
  grid-template-columns: 1fr 10rem;
  grid-column-gap: 1.5rem;
  align-items: center;
  margin-top: 1.5rem;

  border: 2px solid #f0f0f0;
  border-radius: 1.2rem;

  &.highlight {
    border-color: ${props => transparentize(0.4, props.theme.colors.primary)};
  }

  &:hover {
    border-color: ${props => props.theme.colors.primary};
    h4 {
      color: ${props => props.theme.colors.primary};
    }
  }

  span {
    font-size: 1.2rem;
  }
  p {
    font-size: 1.2rem;
    margin: 0;
    margin-top: 0.4rem;
  }
`

export const PerformanceStat = styled.div`
  text-align: right;
  font-size: 1.2rem;
  strong {
    font-size: 2.2rem;
    color: ${props => props.theme.colors.primary};
  }
  span {
    font-size: 1.5rem;
    margin-left: 0.2rem;
  }
`

export const HeadlineStatsGrid = styled.div`
  @media ${sm} {
    display: block;
  }
`

export const Callout = styled(Box)`
  border-radius: 1.2rem;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  border-color: ${props =>
    transparentize(0.8, props.theme.colors[props.$color])};
  position: relative;

  p {
    font-size: 1.4rem;
  }

  > svg {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
`

export const StreakIcon = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        opacity="0.2"
        cx="17"
        cy="17"
        r="16"
        fill="#D4A4D6"
        fillOpacity="0.3"
        stroke="#D4A4D6"
        strokeWidth="2"
      />
      <path
        d="M23 9H16.2331L13 18.2702H16.4586L13 28L22.4737 16.4315H19.0902L23 9Z"
        fill="#E9D6EA"
        stroke="#D4A4D6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const BadgeIcon = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        opacity="0.3"
        cx="17"
        cy="17"
        r="16"
        fill="#D8C7B8"
        fillOpacity="0.3"
        stroke="#D8C7B8"
        strokeWidth="2"
      />
      <path
        d="M11 11H23V16.5184C22.9594 18.5626 22.3697 20.5493 21.3037 22.2339C20.2377 23.9185 18.7417 25.2276 17 26C15.2583 25.2276 13.7623 23.9185 12.6963 22.2339C11.6303 20.5493 11.0406 18.5626 11 16.5184V11Z"
        fill="#F0EEEC"
        stroke="#D8C7B8"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const PerformanceIcon = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        opacity="0.2"
        cx="17"
        cy="17"
        r="16"
        fill="#25C1A9"
        fillOpacity="0.3"
        stroke="#25C1A9"
        strokeWidth="2"
      />
      <path
        d="M15.4444 23L10 18.3548L11.5556 16.4194L15.0556 19.5161L22.0556 11L24 12.9355L15.4444 23Z"
        fill="#CBE6E1"
        stroke="#25C1A9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
