import styled from "styled-components"
import Link from "shared/components/Link"
import { lg } from "mill/utils/breakpoints"

export const TopicPill = styled(Link)`
  border: 2px solid
    ${props =>
      props.secondary
        ? props.theme.colors.secondary
        : props.theme.colors.primary};
  border-radius: 4px;
  padding: 0.4rem 1rem;
  font-size: 1.2rem;
  color: #909090;
`

export const Percentage = styled.div`
  margin-top: 0.5rem;
  display: grid;
  ${props =>
    props.$centered
      ? `
      grid-template-columns: 1fr 1fr;
    `
      : `
  grid-template-columns: 7rem 1fr;
  `}
  strong {
    padding-right: 1.5rem;
    text-align: right;
  }

  ${props =>
    props.right &&
    `
  @media ${lg} {
    grid-template-columns: 1fr 6rem;
    strong {
      grid-column: 2;
    }
    ul {
      grid-column: 1;
      order: -1;
      text-align: right;
    }
    li {
      text-align: right;
    }
    strong {
      text-align: right;
      padding-left: 0.5rem;
      padding-right: 0;
    }
  }
  `}

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    margin: 0 0.5rem 0 0;
  }

  a {
    display: inline-block;
    margin-bottom: 0.5rem;
    &:hover {
      transition: all 0.1s ease-in;
      background: ${props =>
        props.secondary
          ? props.theme.colors.secondary
          : props.theme.colors.primary};
      color: white;
    }
  }

  strong {
    color: ${props =>
      props.secondary
        ? props.theme.colors.secondary
        : props.theme.colors.primary};
    font-size: 2.2rem;

    span {
      margin-left: 0.2rem;
      font-size: 1.5rem;
    }
  }
`

export const TopicGroup = styled.div`
  margin-bottom: 2rem;
  h5 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  ${props =>
    props.right &&
    `
  @media ${lg} {
text-align: right;
}
`}
`

export const FullContainer = styled.div`
  margin: 2rem auto 0 auto;
`

export const Container = styled.div`
  @media ${lg} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
`
