import React, { Fragment, useState } from "react"
import { useQuery } from "@apollo/client"
import Heading from "shared/components/Heading"
import Box from "shared/components/Box"
import Text from "shared/components/Text"
import Overlay, { OverlayContainer } from "shared/components/Overlay"
import moment from "moment"
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from "recharts"
import { placeholderData } from "./placeholderData"
import styled, { useTheme } from "styled-components"
import zIndex from "shared/utils/zIndexLevels"

import FETCH_VIEWER_CAMPAIGN_MEMBERSHIPS from "mill/graphql/FetchViewerCampaignMemberships"

const ChartContainer = styled.div`
  background: white;
  border-radius: 1.2rem;
  border: 2px solid rgba(191, 191, 191, 0.15);
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;

  .recharts-cartesian-axis-tick-line {
    stroke: #c4c4c4;
  }
  .recharts-cartesian-axis-tick-value {
    font-weight: bold;
    font-size: 1.2rem;
    fill: #c4c4c4;
  }
`

const Select = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;

  select {
    color: #808080;
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0.5rem;
    padding-right: 2.5rem;
    margin: 0;
    grid-area: select;
    cursor: pointer;
    z-index: ${zIndex.base + 1};
  }

  i {
    display: inline-block;
    position: absolute;
    right: 0;
    font-size: 1.4rem;
    color: ${props => props.theme.colors.primary};
  }
`

const getMonthsBetween = (start, end) => {
  let startDate = moment(start)
  const endDate = moment(end)
  let monthData = []

  while (endDate > startDate || startDate.format("M") === endDate.format("M")) {
    const newStart = startDate.clone().startOf("month")
    monthData.push(newStart.valueOf())
    startDate.add(1, "month")
  }

  return monthData
}

const Component = () => {
  const theme = useTheme()
  const [timeHorizon, setTimeHorizon] = useState("twelveMonths")
  const { data: queryData, loading } = useQuery(
    FETCH_VIEWER_CAMPAIGN_MEMBERSHIPS
  )

  if (loading || !queryData) return null

  const { viewerCampaignMemberships } = queryData

  const notEnoughData =
    viewerCampaignMemberships.filter(d => d.participated).length <= 1
  const dataSource = notEnoughData ? placeholderData : viewerCampaignMemberships

  // transform dates into integers to allow x-axis to be a continuous number type
  // remove all objects with null recordedParticipationDate
  // sort data by recordedParticipationDate, most recent -> oldest, i.e descending
  const allTimeData = dataSource
    .map(d => {
      d.recordedParticipationDate = moment(
        d.recordedParticipationDate
      ).valueOf()
      return d
    })
    .filter(d => d.participated)
    .filter(d => d.recordedParticipationDate !== null)
    .sort((a, b) => {
      return b.recordedParticipationDate - a.recordedParticipationDate
    })

  const twelveMonthsAgoFromToday = moment().subtract(1, "years").valueOf()
  const twelveMonthsData = allTimeData.filter(
    d => d.recordedParticipationDate > twelveMonthsAgoFromToday
  )
  const data = {
    allTime: allTimeData,
    twelveMonths: twelveMonthsData
  }

  const mostRecentDataPoint = moment(allTimeData[0].recordedParticipationDate)
  const oldestDataPoint = moment(allTimeData.at(-1).recordedParticipationDate)
  const monthsDuration = mostRecentDataPoint.diff(oldestDataPoint, "months")
  const moreThanTwelveMonths = monthsDuration > 12

  const domains = {
    twelveMonths: [
      twelveMonthsAgoFromToday.valueOf(),
      mostRecentDataPoint.valueOf()
    ],
    allTime: [oldestDataPoint.valueOf(), mostRecentDataPoint.valueOf()]
  }
  const months = getMonthsBetween(
    domains[timeHorizon][0],
    domains[timeHorizon][1]
  )

  return (
    <Fragment>
      <Box display="grid" gridTemplateColumns="20rem 1fr" gridGap="2rem">
        <Heading level={4}>Campaign performance</Heading>
        {moreThanTwelveMonths && (
          <Select>
            <select
              value={timeHorizon}
              onChange={e => setTimeHorizon(e.target.value)}>
              <option value="twelveMonths">12 months</option>
              <option value="allTime">All-time</option>
            </select>
            <i className="fa fa-chevron-down" />
          </Select>
        )}
      </Box>
      <Box marginTop="medium">
        <OverlayContainer>
          <ChartContainer>
            <ResponsiveContainer height={400} width="100%">
              <AreaChart
                data={data[timeHorizon]}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0
                }}>
                <defs>
                  <linearGradient id="perf" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor={theme.colors.primary}
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor={theme.colors.primary}
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="recordedParticipationDate"
                  type="number"
                  domain={domains[timeHorizon]}
                  tickCount={months.length}
                  ticks={months}
                  axisLine={false}
                  padding={{ left: 0, right: 0 }}
                  interval={"preserveStartEnd"}
                  tickLine={false}
                  tickFormatter={timeStr =>
                    ` ${moment(new Date(timeStr)).format("MMM")} `
                  }
                />
                <YAxis dataKey="performance" domain={[0, 100]} hide />
                <Tooltip
                  labelFormatter={timeStr =>
                    moment(new Date(timeStr)).format("D MMM YYYY")
                  }
                  formatter={(v, n, o) => {
                    return [
                      `${o.payload.campaignName}: ${Math.round(
                        v.toLocaleString("en-US")
                      )}% Performance`
                    ]
                  }}
                />
                <Area
                  type="monotone"
                  dataKey="performance"
                  stroke={theme.colors.primary}
                  strokeWidth="2"
                  fill="url(#perf)"
                />
              </AreaChart>
            </ResponsiveContainer>

            {notEnoughData && (
              <Overlay opacity={0.85}>
                <Box textAlign="center">
                  <Heading level={2}>Not enough campaign data</Heading>
                  <Box paddingTop="small">
                    <Text>
                      Come back when you've completed a few more campaigns!
                    </Text>
                  </Box>
                </Box>
              </Overlay>
            )}
          </ChartContainer>
        </OverlayContainer>
      </Box>
    </Fragment>
  )
}

export default Component
